









































































































































import { Component, Prop } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import { formatTimestamp } from '@/utils/utils'

@Component({
  name: 'ListVulnInfo',
})
export default class Index extends VueBase {
  @Prop() vuln!: any
  private formatTimestamp(timestamp: number) {
    return formatTimestamp(timestamp)
  }

  private typeColor(type: string) {
    switch (type) {
      case 'Source method':
        return '#1a80f2'
      case '污点来源方法':
        return '#1a80f2'
      case 'Propagation method':
        return '#ff9657'
      case '传播方法':
        return '#ff9657'
      case 'Hazardous method':
        return '#f56262'
      case '危险方法':
        return '#f56262'
      default:
        return '#000'
    }
  }

  private hasNode(tree: any) {
    return (
      tree.vul_level_high ||
      tree.vul_level_info ||
      tree.vul_level_low ||
      tree.vul_level_medium ||
      tree.vul_level_note
    )
  }

  private nodeColor(tree: any) {
    if (tree.vul_level_high) return '#E56363'
    if (tree.vul_level_medium) return '#F49E0B'
    if (tree.vul_level_low) return '#2F90EA'
    if (tree.vul_level_note) return '#ABB2C0'
    if (tree.vul_level_info) return '#DBDBDB'
  }

  private levelColor(level: any) {
    switch (level) {
      case 1:
        return '#E56363'
      case 2:
        return '#F49E0B'
      case 3:
        return '#2F90EA'
      case 4:
        return '#ABB2C0'
      case 5:
        return '#DBDBDB'
    }
  }

  private linkColor(level: any) {
    switch (level) {
      case 1:
        return '#E56363'
      case 2:
        return '#F49E0B'
      case 3:
        return '#2F90EA'
      case 4:
        return '#ABB2C0'
      case 5:
        return '#DBDBDB'
    }
  }

  private bgColor(level_type: number) {
    switch (level_type) {
      case 1:
        return 'rgba(229, 99, 99, 0.1)'
      case 2:
        return 'rgba(244, 158, 11, 0.1)'
      case 3:
        return 'rgba(47, 144, 234, 0.1)'
      case 4:
        return 'rgba(172, 180, 196, 0.1)'
      case 5:
        return 'rgba(219, 219, 219, 0.1)'
    }
  }
}
